export const GAME_SIGNALS = {
  START_GAME: 'START_GAME',
  NEW_CARD: 'NEW_CARD',
  START_BETTING_ROUND: 'START_BETTING_ROUND',
  CARDS_REVEAL: 'CARDS_REVEAL',
  START_HAND: 'START_HAND',
};
export const GAME_STATES = {
  INITIAL_STATE: 'init-state',
  BANKER_DETERMINATION: 'banker-determination',
  DEALING_STARTED: 'dealing-started',
  DEALING_EXPOSED: 'dealing-exposed',
  BET_ROUND: 'bet-round',
  HAND_STARTED: 'hand-started',
  WAIT_FOR_SQUEEZE: 'wait-for-squeeze',
};
export const GSS_SIGNALS = {
  SET_EVENT_STARTED: 'SET_EVENT_STARTED',
  SET_NEW_SHOE: 'SET_NEW_SHOE',
  SET_HAND_STARTED: 'SET_HAND_STARTED',
  SET_CARD_EXPOSED: 'SET_CARD_EXPOSED_',
  SET_CARDS_DEALING: 'SET_CARDS_DEALING_',
  SET_BANKER_SET: 'SET_BANKER_SET',
  SET_BANKER_DETERMINATION: 'SET_BANKER_DETERMINATION',
  SET_BANKER_2_CARD_EXPOSED: 'SET_BANKER_2_CARD_EXPOSED',
  SET_BANKER_3_CARD_DEALT: 'SET_BANKER_3_CARD_DEALT',
  SET_BANKER_3_CARD_EXPOSED: 'SET_BANKER_3_CARD_EXPOSED',
};
