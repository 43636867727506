import { Outlet } from 'react-router';
import { CardReaderStateProvider } from './CardReaderStateProvider';
import { GameCoordinatorStateProvider } from './GameCoordinatorStateProvider';

const StudioContextLayout = () => (
  <GameCoordinatorStateProvider>
    <CardReaderStateProvider>
      <Outlet />
    </CardReaderStateProvider>
  </GameCoordinatorStateProvider>
);

export default StudioContextLayout;
