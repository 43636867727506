import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react';
import { Signal, Log, SIGNAL_TYPES } from './types';
import { GAME_STATES } from './helpers/gss/consts';
import { clearSequenceNumber } from './helpers/gss/sequenceNumber';
import {
  LocalStorageKeys,
  deleteFromLocalStorage,
  getFromLocalStorage,
  saveToLocalStorage,
} from 'utils/localStorage';

interface Props {
  children: React.ReactElement;
}

export interface GameCoordinatorContextValue {
  processing: boolean;
  receivedSignal: Signal | null;
  logs: Log[] | null;
  gameState: string | null;
  storedEvent: string | null;
  handleEventSelected: (eventId: string) => void;
  handleLog: (signal: Signal) => void;
  setProcessing: Dispatch<SetStateAction<boolean>>;
  setGameState: Dispatch<SetStateAction<string | null>>;
  signalProcessor: (signal: Signal) => void;
  handleGameStateReset: () => void;
}

const createLog = (signal: Signal) => {
  return {
    value: signal.type ? `${signal.type}: ${signal.value}` : `${signal.value}`,
    createdOn: new Date().getTime(),
  };
};

const initialState = {
  processing: false,
  receivedSignal: null,
  logs: null,
  gameState: null,
  storedEvent: null,
  handleLog: () => {},
  setProcessing: () => {},
  handleEventSelected: () => {},
  setGameState: () => null,
  signalProcessor: () => null,
  handleGameStateReset: () => null,
};

const GameCoordinatorContext = createContext<GameCoordinatorContextValue>(initialState);

export const GameCoordinatorStateProvider = ({ children }: Props) => {
  const [processing, setProcessing] = useState(false);

  const [storedEvent, setStoredEvent] = useState<string | null>(() =>
    getFromLocalStorage(LocalStorageKeys.EVENT_ID)
  );
  const [receivedSignal, setReceivedSignal] = useState<Signal | null>(null);
  const [logs, setLogs] = useState<Log[] | null>(() => getFromLocalStorage(LocalStorageKeys.LOGS));
  const [gameState, setGameState] = useState<string | null>(() =>
    getFromLocalStorage(LocalStorageKeys.GAME_STATE)
  );

  useEffect(() => {
    setReceivedSignal(null);
  }, []);

  useEffect(() => {
    saveToLocalStorage(LocalStorageKeys.LOGS, logs);
  }, [logs]);

  useEffect(() => {
    saveToLocalStorage(LocalStorageKeys.GAME_STATE, gameState);
    saveToLocalStorage(LocalStorageKeys.EVENT_ID, storedEvent);
  }, [storedEvent, gameState]);

  const handleLog = useCallback((signal: Signal) => {
    const log = createLog(signal);
    setLogs((prevState) => {
      if (prevState) {
        return [...prevState, log];
      }
      return [log];
    });
  }, []);

  const resetEventStateVariables = () => {
    setGameState(GAME_STATES.INITIAL_STATE);
    setProcessing(false);
    setReceivedSignal(null);
    setLogs([]);
    clearSequenceNumber();
    saveToLocalStorage(LocalStorageKeys.DEALT_CARDS_IN_ROUND, 1);
    saveToLocalStorage(LocalStorageKeys.DEALING_ROUND, 1);
    saveToLocalStorage(LocalStorageKeys.NEXT_BETTING_ROUND, 1);
    deleteFromLocalStorage(LocalStorageKeys.DEALING_SIGNALS);
    deleteFromLocalStorage(LocalStorageKeys.SUCCESS_SIGNAL_SENT);
  };

  const handleEventSelected = (eventId: string) => {
    setStoredEvent(eventId);
    resetEventStateVariables();
  };

  const handleGameStateReset = () => {
    setStoredEvent('');
    resetEventStateVariables();
  };

  const signalProcessor = useCallback(
    (signal: Signal) => {
      console.log(`signalProcessor: Received signal [${JSON.stringify(signal)}]`);
      switch (signal.type) {
        case SIGNAL_TYPES.NEW_CARD:
        case SIGNAL_TYPES.SET_BANKER:
        case SIGNAL_TYPES.MANUAL:
          setReceivedSignal(signal);
          break;
        default:
          handleLog(signal);
      }
    },
    [handleLog]
  );

  return (
    <GameCoordinatorContext.Provider
      value={{
        processing,
        receivedSignal,
        logs,
        gameState,
        storedEvent,
        handleEventSelected,
        handleLog,
        setProcessing,
        setGameState,
        signalProcessor,
        handleGameStateReset,
      }}
    >
      {children}
    </GameCoordinatorContext.Provider>
  );
};

export const useGameCoordinatorContext = () => useContext(GameCoordinatorContext);
