import { CardData, SuitMap } from '../@types/game';

export const getValueForRank = (rank: string): number => {
  const num = Number(rank);

  if (Number.isNaN(num)) return 0;
  return num;
};

export const gssStringToCardData = (cardString: string): CardData | null => {
  if ((cardString?.length ?? 0) !== 2) return null;

  if (cardString === 'NV') return null;

  const theSuit = SuitMap[cardString[0]];
  const theRank = cardString[1];

  return {
    code: `${theSuit.suit}${theRank}`,
    suit: theSuit,
    rank: theRank,
    label: `${theRank}${theSuit.symbol}`,
    value: getValueForRank(theRank),
  };
};

export const getScoreForCards = (cards: CardData[]) => {
  let score = 0;
  cards?.forEach((card) => {
    score += card?.value ?? 0;
  });
  return score % 10;
};

export const isNatural = (cards: CardData[]) => {
  if (cards?.length !== 2) return false;
  const score = getScoreForCards(cards);
  return score === 8 || score === 9;
};

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export const cardDataCodeToGSSString = (cardCode: string) => {
  const aceCardRank = 'A';
  const gssAceCardRank = '1';
  if (cardCode.endsWith(aceCardRank)) {
    return cardCode.slice(0, -1) + gssAceCardRank;
  }
  return cardCode;
};
