export enum SIGNAL_TYPES {
  NEW_CARD = 'NEW_CARD',
  ALERT = 'ALERT',
  MANUAL = 'MANUAL',
  VALIDATION_CARD = 'VALIDATION_CARD',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
  DEVICE_CONNECTED = 'DEVICE_CONNECTED',
  DEVICE_DISCONNECTED = 'DEVICE_DISCONNECTED',
  SET_BANKER = 'SET_BANKER',
}

export interface Signal {
  type: SIGNAL_TYPES;
  value: string;
}

export interface Log {
  value: string;
  createdOn: number;
}

export type HandleSignal = (signal: Signal) => void;

export type ParseSignal = (receivedData: string) => void;

export const sendManualSignal = (signalProcessor: HandleSignal, signal: string) => {
  console.log(`sendManualSignal: Received signal [${signal}]`);
  signalProcessor({ type: SIGNAL_TYPES.MANUAL, value: signal });
};

export const sendAlertSignal = (signalProcessor: HandleSignal, signal: string) => {
  console.log(`sendAlertSignal: Received signal [${signal}]`);
  signalProcessor({ type: SIGNAL_TYPES.ALERT, value: signal });
};
